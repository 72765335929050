import { WatchOptions } from 'vue';

export default {
  'SelbstbeteiligungNeubauleistung.tarif.SelbstbehaltRohbau': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'tarif');
    }
  },
  'SelbstbeteiligungNeubauleistung.tarif.SelbstbehaltAusbau': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'tarif');
    }
  },
  'SelbstbeteiligungNeubauleistung.variant1.SelbstbehaltRohbau': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'variant1');
    }
  },
  'SelbstbeteiligungNeubauleistung.variant1.SelbstbehaltAusbau': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'variant1');
    }
  },
  'SelbstbeteiligungNeubauleistung.variant2.SelbstbehaltRohbau': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'variant2');
    }
  },
  'SelbstbeteiligungNeubauleistung.variant2.SelbstbehaltAusbau': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'variant2');
    }
  },
  'SelbstbeteiligungNeubauleistung.variant3.SelbstbehaltRohbau': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'variant3');
    }
  },
  'SelbstbeteiligungNeubauleistung.variant3.SelbstbehaltAusbau': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request-for-variant', true, 'variant3');
    }
  },
  'MitversicherungVonFeuer.BaudauerInMonaten': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
  'MitversicherungVonFeuer.Baunebenkosten': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
  'VersicherungssummeNeubauleistung.BereitsVerbauteLeistung.value': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
  'VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe1.Versicherungssumme': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
  'VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe2.Versicherungssumme': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
  'VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe1.BeitragssatzPercent': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
  'SachenImGefahrenbereich.Erstrisikosumme': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
  'SachenImGefahrenbereich.BeitragssatzZuschlag': function (newVal: any, oldVal: any) {
    // eslint-disable-next-line no-underscore-dangle
    if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
      this.$emit('change-data-for-price-request', true);
    }
  },
  Erstrisikosummen: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  Grundungsmabnahmen: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  MitversicherungBesondererBaumabnahmen: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  HilsbautenUndBauhilfsstoffe: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  Altbau: {
    handler(newVal: any, oldVal: any) {
      console.log('Altbau');
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  AltbauNachAZ5155: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  AltbauNachAZ5180: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  AltbauNachAZ5181: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  Nachhaftung: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  MitversicherungGewasserrisiko: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  Deckungseinschrankungen: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
} as Record<string, any>;

